<template>
  <v-layout row wrap>
    <v-flex xs12 class="no-print">
      <v-card width="800">
        <v-toolbar dense dark class="secondary">
          <v-toolbar-title>Adjust session note signatures</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" autocomplete="off" v-model="validForm">
            <v-layout row wrap>
              <v-flex xs5>
                <v-text-field
                  box
                  :disabled="loading"
                  label="Id GOOD sigtarure"
                  v-model="goodSignatureId"
                  prepend-inner-icon="fa-signature"
                  mask="########"
                  :rules="[required]"
                  required
                  @change="getGoodSignature"
                  :loading="loading"
                ></v-text-field>
                <div style="height: 60px" class="mb-2 ml-2">
                  <img style="height: 100%" :src="goodSignature" />
                </div>
              </v-flex>
              <v-flex xs2>
                <v-card flat>
                  <v-card-text class="text-xs-center"><v-icon>fa-3x fa-right-long</v-icon></v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  box
                  :disabled="loading"
                  label="Id BAD sigtarure"
                  v-model="badSignatureId"
                  prepend-inner-icon="fa-signature"
                  mask="########"
                  :rules="[required]"
                  required
                  @change="getBadSignature"
                  :loading="loading"
                ></v-text-field>
                <div style="height: 60px" class="mb-2 ml-2">
                  <img style="height: 100%" :src="badSignature" />
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="loading || !validForm" :loading="loading" color="primary" @click="saveSignature">Adjust</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import sessionServicesApi from "@/services/api/SessionServices";

export default {
  data() {
    return {
      loading: false,
      required: (value) => !!value || "This field is required.",
      validForm: false,
      goodSignatureId: null,
      badSignatureId: null,
      goodSignature: null,
      badSignature: null
    };
  },

  methods: {
    async saveSignature() {
      this.$confirm(`Are you sure you want to copy signature ${this.goodSignatureId} to signature ${this.badSignatureId}`).then(async (res) => {
        if (res) {
          this.loading = true;
          try {
            await sessionServicesApi.copySessionSignature(this.goodSignatureId, this.badSignatureId);
            this.$refs.form.reset();
            this.goodSignature = null;
            this.badSignature = null;
            this.$toast.success("Signature copied successful");
          } catch (error) {
            this.$toast.error(error);
          } finally {
            this.loading = false;
          }
        }
      });
    },

    async getGoodSignature() {
      this.goodSignature = null;
      if (!this.goodSignatureId) return;
      this.loading = true;
      try {
        this.goodSignature = await sessionServicesApi.getSessionSignature(this.goodSignatureId);
        if (!this.goodSignature) {
          this.$toast.warning("Good signature was not found");
        }
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getBadSignature() {
      this.badSignature = null;
      if (!this.badSignatureId) return;
      this.loading = true;
      try {
        this.badSignature = await sessionServicesApi.getSessionSignature(this.badSignatureId);
        if (!this.badSignature) {
          this.$toast.warning("Bad signature was not found");
        }
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
